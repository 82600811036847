import { Text, type TextProps } from '@/ui';

export const TypeText = (props: TextProps) => {
  return (
    <Text
      bg="gray.100"
      borderRadius="md"
      color="gray.600"
      fontSize="sm"
      fontWeight="medium"
      height="fit-content"
      lineHeight="normal"
      px="1.5"
      py="0.5"
      {...props}
    />
  );
};
