import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { gqlClient } from '@/lib';
import type { QueryError } from '@/utils/errors';
import { createLibraryQueryKey } from '@/utils/queryKeys';
import type {
  GetLibraryQuery,
  GetLibraryQueryVariables,
} from './useLibrary.generated';

const query = gql`
  query GetLibrary {
    getLibrary {
      id
      type
      title
      imageUrl
      editUrl
      publicUrl
      openedAt
      createdAt
      isSaved
      timeZone
      permissions {
        role
        all {
          id
          name
          email
          avatar
        }
      }
      styles {
        hue
        saturation
        lightness
        bold
      }
    }
  }
`;

export type LibraryItem = GetLibraryQuery['getLibrary'][number];

const selector = (data: GetLibraryQuery): LibraryItem[] => {
  return data.getLibrary;
};

export const useLibrary = () => {
  const queryKey = createLibraryQueryKey();

  return useQuery<GetLibraryQuery, QueryError, LibraryItem[]>({
    queryKey,
    select: selector,
    queryFn: async () => {
      const response = await gqlClient.request<
        GetLibraryQuery,
        GetLibraryQueryVariables
      >(query);

      return {
        ...response,
        getLibrary: response.getLibrary.map((item) => ({
          ...item,
          // the `type` in the library schema differs from the type in a schedule
          contextId: 'library-' + item.id,
        })),
      };
    },
  });
};
